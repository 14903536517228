export const routes = [
  {
    path: "/:lang/partners/:id/:provider?",
    name: "partner-switching",
    component: () => import(/* webpackChunkName: 'common-landing-page' */ "@/components/Partners/Partner.view.vue"),
  },
  {
    path: "/:lang/telcoPaymentHandler",
    name: "telcoPaymentHandler",
    component: () => import(/* webpackChunkName: 'telcoPaymentHandler' */ "@/components/Telco/telcoPaymentHandler.vue"),
  },
  {
    path: "/:lang/telcoPaymentHandler/:referenceid",
    name: "telcoPaymentHandler",
    component: () => import(/* webpackChunkName: 'telcoPaymentHandler' */ "@/components/Telco/telcoPaymentHandler.vue"),
  },
  {
    path: "/tv",
    name: "tvGeneration",
    component: () => import(/* webpackChunkName: 'tv-generation' */ "@/components/TV/PinGeneration.vue"),
  },
	{
		path: "/pairing",
		name: "loginWithPinView",
		component: () => import(/* webpackChunkName: 'tv-generation' */ "@/components/TV/LoginWithPinView.vue"),
	},

  // deeplink for IOS / ANDROID
  {
    path: "/TermsOfUse",
    name: "termsofuse",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/TermsOfUse/termsofuse.vue"),
  },
  {
    path: "/TermsOfUse-ar",
    name: "termsofuse-ar",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/TermsOfUse/termsofuse-ar.vue"),
  },


  {
    path: "/PrivacyPolicy",
    name: "privacyPolicy",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/PrivacyPolicy/privacyPolicy.vue"),
  },
  {
    path: "/PrivacyPolicy-ar",
    name: "privacyPolicy-ar",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/PrivacyPolicy/privacyPolicy-ar.vue"),
  },
  // end deeplink

  {
    path: "/en/TermsOfUse",
    name: "termsofuse",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/TermsOfUse/termsofuse.vue"),
  },
  {
    path: "/ar/TermsOfUse",
    name: "termsofuse-ar",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/TermsOfUse/termsofuse-ar.vue"),
  },

  {
    path: "/:lang(en|ar)?/:objecttype(series|content)/:type(genre|category|language|productionyear)?/:value",
     name: "screen-meta-redirection",
     component: () => import(/* webpackChunkName: 'screen-meta-tag' */ "@/components/MetaTagRedirection/index.vue"),
     props: (route) => ({
      ...route.params,
      }),
  },
  
  {
    path: "/en/terms-of-use",
    name: "termsofuse",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/TermsOfUse/termsofuse.vue"),
  },


  {
    path: "/en/PrivacyPolicy",
    name: "privacyPolicy",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/PrivacyPolicy/privacyPolicy.vue"),
  },
  {
    path: "/ar/PrivacyPolicy",
    name: "privacyPolicy-ar",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/PrivacyPolicy/privacyPolicy-ar.vue"),
  },


  {
    path: "/en/privacy-policy",
    name: "privacyPolicy",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/PrivacyPolicy/privacyPolicy.vue"),
  },
  
  {
    path: "/ar/terms-of-use",
    name: "termsofuse-ar",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/TermsOfUse/termsofuse-ar.vue"),
  },
  {
    path: "/ar/privacy-policy",
    name: "privacyPolicy-ar",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/PrivacyPolicy/privacyPolicy-ar.vue"),
  },
  {
    path: "/:lang(en|ar)?/Profile",
    name: "Profile",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/Profile/Profile.vue"),
  },
  {
    path: "/cast",
    name: "Cast",
    component: () => import(/* webpackChunkName: 'watchlist-page' */ "@/components/CastandCrew/Casting.vue"),
  },
  {
    path: "/emailVerify",
    name: "subscriberVerify",
    component: () =>
      import(/* webpackChunkName: 'subscriberVerfiy-page' */ "@/components/Templates/subscriberVerify.vue"),
  },
  {
    path: "/detailComponent/:category/:title",
    name: "detailComponent",
    component: () => import(/* webpackChunkName: 'detailComponent' */ "@/components/Templates/detailComponent.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/detail/:contentid",
    name: "detail",
    component: () => import(/* webpackChunkName: 'detail' */ "@/components/MobileTemplates/detail.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path:"/:lang(en|ar)?/seeAllContinueWatch",
    name:"seeAllContinueWatch",
    component: () => import(/* webpackChunkName: 'seeAllContinueWatch' */ "@/components/ContinueWatch/continueWatchSeeAll.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path:"/:lang(en|ar)?/recommendedSeeAllScreen",
    name:"recommendedSeeAllScreen",
    component: () => import(/* webpackChunkName: 'seeAllContinueWatch' */ "@/components/screen/recommendedSeeAllScreen.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path:"/trailerDeeplink/:contentId",
    name:"trailerDeeplink",
    component: () => import(/* webpackChunkName: 'trailerDeeplink' */ "@/components/notificationDeeplinks/trailerDeeplink.vue"),
  },
  {
    path:"/contentPlayDeeplink/:contentId",
    name:"contentPlayDeeplink",
    component: () => import(/* webpackChunkName: 'trailerDeeplink' */ "@/components/notificationDeeplinks/contentPlayDeeplink.vue"),
  },
  {
    path:"/:lang(en|ar)?/switchProfile",
    name:"switchProfile",
    component: () => import(/* webpackChunkName: 'switchProfile' */ "@/components/Profile/SwitchProfile.vue"),
  },
  {
    path:"/addProfile",
    name:"addProfile",
    component: () => import(/* webpackChunkName: 'AddProfile' */ "@/components/Profile/AddProfile.vue"),
  },
  {
    path: "/:lang(en|ar)?/series/:mediaTitle/:contentId",
    name: "detailPage",
    children: [
      // { path: '/', component: () => import(/* webpackChunkName: 'episodeCard' */ "@/components/DetailPage/episodeCard.vue"), },
      {
        path: "season/:id",
        component: () => import(/* webpackChunkName: 'episodeCard' */ "@/components/DetailPage/episodeCard.vue"),
        name: "episodes",
      },
      {
        path: "season/:id/episode/:episodeId/:isPlayBackActive",
        component: () => import(/* webpackChunkName: 'episodeCard' */ "@/components/DetailPage/episodeCard.vue"),
        name: "episodePlayback",
      },
      {
        path: "trailer",
        component: () => import(/* webpackChunkName: 'trailerCard' */ "@/components/DetailPage/trailerCard.vue"),
        name: "trailer",
      },
      {
        path: "related",
        component: () => import(/* webpackChunkName: 'trailerCard' */ "@/components/DetailPage/relatedCard.vue"),
        name: "related",
      },
      {
        path: "morelikethis",
        component: () => import(/* webpackChunkName: 'trailerCard' */ "@/components/DetailPage/moreLikeThis.vue"),
        name: "MoreLikeThis",
      },
      {
        path: "trailer/:trailerId/:isPlayBackActive",
        component: () => import(/* webpackChunkName: 'trailerCard' */ "@/components/DetailPage/trailerCard.vue"),
        name: "trailerPlaybackSeries",
      },

      // UserProfile will be rendered inside User's <router-view>
      // when /user/:id/profile is matched
      // { path: 'profile', component: UserProfile },

      // // UserPosts will be rendered inside User's <router-view>
      // // when /user/:id/posts is matched
      // { path: 'posts', component: UserPosts }
    ],

    component: () => import(/* webpackChunkName: 'detail' */ "@/components/DetailPage/detailPage.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },

  {
    path: "/:lang(en|ar)?/movie/:mediaTitle/:contentId",
    name: "detailPageMovie",
    children: [
      // { path: '/', component: () => import(/* webpackChunkName: 'episodeCard' */ "@/components/DetailPage/episodeCard.vue"), },
      { path: 'trailer/:trailerId/:isPlayBackActive', component: () => import(/* webpackChunkName: 'trailerCard' */ "@/components/DetailPage/trailerCard.vue") , name:"movieTrailerPlay"},
      { path:'/', component: () => import(/* webpackChunkName: 'trailerCard' */ "@/components/DetailPage/trailerCard.vue") , name:"movieTrailer" },
      { path: '/', component: () => import(/* webpackChunkName: 'trailerCard' */ "@/components/DetailPage/relatedCard.vue") , name:"relatedMovie" },
      { path: "morelikethis", component: () => import(/* webpackChunkName: 'trailerCard' */ "@/components/DetailPage/moreLikeThis.vue"), name: "moreLikeThis" },
      // UserProfile will be rendered inside User's <router-view>
      // when /user/:id/profile is matched
      // { path: 'profile', component: UserProfile },

      // // UserPosts will be rendered inside User's <router-view>
      // // when /user/:id/posts is matched
      // { path: 'posts', component: UserPosts }
    ],
    component: () => import(/* webpackChunkName: 'detail' */ "@/components/DetailPage/detailPage.vue"),

    props: (route) => ({
      ...route.params,
    }),
  },

  {
    path: "/movie/:title/:contentId/play",
    component: () => import(/* webpackChunkName: 'trailerCard' */ "@/components/DetailPage/detailPage.vue"),
  },

  // {
  //   path: '/series/:contentId/season/:id/episode/:episodeId/play', component: () => import(/* webpackChunkName: 'trailerCard' */ "@/components/DetailPage/episodeCard.vue"),
  // },

  {
    path: "/:lang(en|ar)?/screen/:section",
    name: "screen",
    component: () => import(/* webpackChunkName: 'screen' */ "@/components/screen/screen.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/:lang(en|ar)?/section-screen/:section",
    name: "section-screen",
    component: () => import(/* webpackChunkName: 'screen' */ "@/components/screen/sectionWiseScreen.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/:lang(en|ar)?/watchlist",
    name: "Watchlist",
    component: () => import(/* webpackChunkName: 'watchlist-page' */ "@/components/watchlist/watchlist.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/menu/watchlist",
    // name: "Watchlist",
    component: () => import(/* webpackChunkName: 'watchlist-page' */ "@/components/watchlist/watchlist.vue"),
  },
  {
    path: "/menu/bold",
    // name: "Watchlist",
    component: () => import(/* webpackChunkName: 'watchlist-page' */ "@/components/Home/Home.vue"),
  },
  {
    path: "/menu/plan",
    // name: "Watchlist",
    component: () => import(/* webpackChunkName: 'watchlist-page' */ "@/components/Home/Home.vue"),
  },
  {
    path: "/bundles",
    name: "Bundles",
    component: () => import(/* webpackChunkName: 'bundles' */ "@/components/Bundles/bundles.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/live",
    name: "Live",
    component: () => import(/* webpackChunkName: 'live' */ "@/components/Live/live.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/music",
    name: "Music",
    component: () => import(/* webpackChunkName: 'music' */ "@/components/Music/music.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/tvshows",
    name: "Tvshows",
    component: () => import(/* webpackChunkName: 'tvshows' */ "@/components/Tvshows/tvshows.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/movie",
    name: "Movie",
    component: () => import(/* webpackChunkName: 'movies' */ "@/components/Movie/movie.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/bold",
    name: "Bold",
    component: () => import(/* webpackChunkName: 'bold component' */ "@/components/Templates/boldComponent.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/series",
    name: "SERIES",
    component: () => import(/* webpackChunkName: 'series component' */ "@/components/series/series.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/movies",
    name: "movies",
    component: () => import(/* webpackChunkName: 'movies component' */ "@/components/Movies/movies.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/RAMADAN",
    name: "RAMADAN",
    component: () => import(/* webpackChunkName: 'ramadan component' */ "@/components/ramadan/ramadan.vue"),
  },
  {
    path: "/FREEMIUM",
    name: "FREEMIUM",
    component: () => import(/* webpackChunkName: 'ramadan component' */ "@/components/freemium/freemium.vue"),
  },
  {
    path: "/KIDS",
    name: "KIDS",
		component: () => import(/* webpackChunkName: 'kids component' */ "@/components/kids/kids.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/search/:query",
    name: "SearchPage",
    component: () => import(/* webpackChunkName: 'search page' */ "@/components/Search/SearchPage.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/:lang(en|ar)?",
    name: "Home",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/Home/Home.vue"),
    children: [
      {
        path: "/:lang(en|ar)?/plan/:planid",
        name: "plandeeplink",
        component: () => import(/* webpackChunkName: 'plandeeplink' */ "@/components/Popups/planDeeplink.vue"),
      },
      {
        path: "/:lang(en|ar)?/viewContent/:contentId",
        name: "contentdeeplink",
        component: () => import(/* webpackChunkName: "contentdeeplink" */ "@/components/Popups/viewContent.vue"),
      },
      {
        path: "/:lang(en|ar)?/viewTicket/:ticketId",
        name: "ticketdeeplink",
        component: () => import(/* webpackChunkName: "ticketdeeplink" */ "@/components/Popups/viewTicket.vue"),
      },
      {
        path: "/:lang(en|ar)?/viewPlanAndCoupon/:planId/:coupon?",
        name: "planandcoupondeeplink",
        component: () =>
          import(/* webpackChunkName: "planandcoupondeeplink" */ "@/components/Popups/viewPlanAndCoupon.vue"),
      },
      {
        path: "/:lang(en|ar)?/viewallplans/:planid?/:coupon?",
        name: "seeAllPlans",
        component: () =>
          import(/* webpackChunkName: "seeAllPlans" */ "@/components/Popups/SeeAllPlans.vue"),
      },
    ],
  },
  {
    path: "/lookup",
    // name: "Home",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/Home/Home.vue"),
  },
  {
    path: "/help",
    // name: "Home",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/Home/Home.vue"),
  },
  {
    path: "/about",
    // name: "Home",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/Home/Home.vue"),
  },
  {
    path: "/content/details/:id",
    // name: "contentDetails",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/Home/Home.vue"),
  },
  {
    path: "*",
    name: "ErrorPage",
    component: () => import(/* webpackChunkName: 'ErrorPage' */ "@/components/NotFound/Error404.vue"),
  },
];

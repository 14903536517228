import { errorServiceHandler, errorHandlerMiddleware, setHeader } from "./helper";
import { store } from "@/store/store";

export const getConfig = () => {
	return new Promise((resolve, reject) => {
		fetch(
			//store.getters.noorplay_test,
			// store.getters.noorplay_beta,
			// store.getters.net5_config,
			// store.getters.nammaflix_config,
			store.getters.noorplay_config,
			{
				method: "GET",
			}
		)
			.then(errorHandlerMiddleware)
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const getDeckingConfig = (context, payload) => {
	return new Promise((resolve, reject) => {
		fetch(
			//store.getters.decking_config,
			//store.getters.noorplay_beta,
			// store.getters.net5_config,
			// store.getters.nammaflix_config,
			payload,
			{
				method: "GET",
			}
		)
			.then(errorHandlerMiddleware)
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const deviceRegister = (context, payload) => {
	return new Promise((resolve, reject) => {
		if (!payload) {
			reject({ error: "payload not found" });
		}
		fetch(`${context.state.vSmsBaseUrl}subscriberv2/v1/device/register/${payload.providerid}?hash=${payload.hash}`, {
			method: "POST",
			body: payload.enc,
		})
			.then(errorHandlerMiddleware)
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getDisplayLanguageAPI = (context) => {
	return new Promise((resolve, reject) => {
		fetch(`${context.state.vSmsBaseUrl}subscriberv2/v1/i18n/language`, {
			method: "GET",
			headers: setHeader("json"),

			// {
			//   "Content-Type": "application/json",
			//   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
			// },
		})
			.then(errorHandlerMiddleware)
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getCountryList = () => {
	return new Promise((resolve, reject) => {
		fetch("https://d1v8zxa9gk5f.cloudfront.net/COMMONFILES/country-phone.json", {
			method: "GET",
		})
			.then((data) => {
				return data.json();
			})
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getClientIpAddress = () => {
	return new Promise((resolve, reject) => {
		fetch("https://api.ipify.org/?format=json")
			.then((data) => {
				return data.json();
			})
			.then((data) => {
				resolve(data);
			})
			.then((error) => {
				reject(error);
			});
	});
};

export const getCaptcha = (context) => {
	return new Promise((resolve, reject) => {
		fetch(`${context.state.vSmsBaseUrl}subscriberv2/v1/captcha`, {
			method: "POST",
			headers: setHeader(null, "deviceToken"),
		})
			.then(errorHandlerMiddleware)
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getLanguagesByCodes = (context) => {
	return new Promise((resolve, reject) => {
		fetch("https://d1v8zxa9gk5f.cloudfront.net/COMMONFILES/language.json")
			.then(errorHandlerMiddleware)

			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
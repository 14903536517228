export const appConfig = (state, data) => {
  state.appConfig = data;
};
export const setCountry = (state, data) => {
  if(data) {
    state.country = data;
  } else  {
    state.country = localStorage.getItem("currentUserCountry") ? JSON.parse(localStorage.getItem("currentUserCountry")) : null;
    console.log("THE ELSE BLOCK - COUNTRY ", state.country)
  }
}; 
export const setToken = (state, data) => {
  if (data) {
    state.token = data;
  } else {
    state.token = null;
  }
};
export const setSubscriberid = (state, data) => {
  state.subscriberid = data;
};
export const setProfileid = (state, data) => {
  state.profileid = data;
};
export const setProfilePicture = (state, data) => {
  state.profilePicture = data;
};
export const commitappconfig = (state) => {
  if (localStorage.getItem("appConfig")) {
    let config = JSON.parse(localStorage.getItem("appConfig"));
    if (config) {
      state.appConfig = config;
    }
  } else {
    state.appConfig = null;
  }
};
export const commitbaseurls = (state, data) => {
  if (data) {
    state.vRecommendBaseUrl = data.vRECOMMEND;
    state.vChargeBaseUrl = data.vCHARGE;
    state.vCmsBaseUrl = data.vCMS;
    state.vCrmBaseUrl = data.vCRM;
    state.vDrmBaseUrl = data.vDRM;
    state.vSmsBaseUrl = data.vSMS;
  }
};

export const setSubscriberProfileDetails = (state, data) => {
  state.subscriberProfileDetails = data;
};

export const commitsubscriberdetails = (state) => {
  if (localStorage.getItem("subscriberDetails")) {
    let subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails"));

    window.dispatchEvent(
      new CustomEvent("localstorage-updated", {
        detail: {
          storage: localStorage.getItem("subscriberDetails"),
        },
      })
    );

		if (subscriberDetails && subscriberDetails.data && subscriberDetails.data.subscriberid) {
      state.subscriberid = subscriberDetails.data.subscriberid;
    }
    
    if(localStorage.getItem("currentProfileId")) {
      state.profileid = localStorage.getItem("currentProfileId")
		} else if (subscriberDetails && subscriberDetails.data && subscriberDetails.data.profileid) {
      state.profileid = subscriberDetails.data.profileid;
    }

    if(localStorage.getItem("subscriberProfileDetails")) {
      state.subscriberProfileDetails = JSON.stringify(localStorage.getItem("subscriberProfileDetails"));
    }
    
		if (subscriberDetails && subscriberDetails.data && subscriberDetails.data.picture) {
      state.profilePicture = subscriberDetails.data.picture;
    }
  } else {
    state.subscriberid = null;
    state.profileid = null;
    state.profilePicture = null;
  }
};
export const setGenreData = (state, data) => {
  console.log("setGenreData", data);
  state.genreData = data;
};

export const setAvailabilityList = (state, data) => {
  state.availabilityList = data;
};

export const setSubscriptionList = (state, data) => {
  state.subscriptionList = data;
};

export const setPurchasesList = (state, data) => {
  state.purchaseList = data;
};

export const setNewSubscription = (state, data) => {
  state.newSubscription = data;
  console.log("data mutation------>", data);
};

export const setNewPurchase = (state, data) => {
  state.newPurchase = data;
};

export const setCurrentBrowser = (state, data) => {
  state.currentBrowser = data;
};

export const setDisplayLanguage = (state, data) => {
  state.displayLanguage = data;
};

export const setDisplayLanguageCode = (state, data) => {
  state.displayLanguageCode = data;
};

export const setRtl = (state, data) => {
  state.rtl = data;
};

export const setIsMovieEnabled = (state, data) => {
  state.isMovieEnabled = data;
};

export const setUnauthorizedErrorOccured = (state, data) => {
  state.unauthorizedErrorOccured = data;
};

export const setFirebaseUserUID = (state, data) => {
  state.firebaseUserUID = data;
};
export const setRegActiveDeeplink = (state, data) => {
  state.regActiveDeeplink = data;
};

export const setGatewayList = (state, data) => {
  state.gatewayList = data;
};

export const setIsManageCards = (state, data) => {
  state.isManageCards = data;
};

export const setContinueWatchData = (state,data)=>{
  state.ContinueWatchData = data; 
}
export const setAudioAndSubtitleLangState = (state, data) => {
  state.audioAndSubtitleLangState = data;
};

export const setAllLanguageFullForm = (state, data) => {
  state.allLanguageFullForm = data;
};

export const setEpisodePaginationInProgress = (state, data) => {
  state.episodePaginationInProgress = data;
};

export const setPlanDeeplinkPath = (state, data) => {
  state.planDeeplinkPath = data;
};

export const setCountryListByCode = (state, data) => {
	state.countryListByCode = data;
};
export const setCountryCodesList= (state, data) => {
  state.countryCodesList = data;
};
export const setPairingFlag = (state, data) => {
	state.pairingFlag = data;
};

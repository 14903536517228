const state = {
	//config url
	vlive_config: "https://vlive-config.firebaseio.com/preprod/web/vlive/v1.json?print=pretty",
	net5_config: "https://vlive-config.firebaseio.com/prod/web/net5/v1.json",
	nammaflix_config: "https://vlive-config.firebaseio.com/prod/web/nammaflix/v1.json",
	// noorplay_config: "https://vlive-config.firebaseio.com/prod/web/noorplay/v1.json",

	noorplay_test: "https://noorplay-web-application-default-rtdb.firebaseio.com/noorplay-testv1.json",

	noorplay_beta: "https://noorplay-web-application-default-rtdb.firebaseio.com/noorplay-betav1.json", // Test - Latest

	noorplay_config: "https://d2xowqqrpfxxjf.cloudfront.net/noorplay/web-noorplayv2.json",
	// "https://noorplay-web-application-default-rtdb.firebaseio.com/noorplay-prodv1.json", // NOORPLAY PRODV1 WITH TOPTEN
	//"https://d2xowqqrpfxxjf.cloudfront.net/noorplay/web-noorplayv2.json",
	//"https://noorplay-web-application-default-rtdb.firebaseio.com/noorplay-vodafone-qatar.json",
	// "https://noorplay-web-application-default-rtdb.firebaseio.com/noorplay-iraq.json",
	//	"https://noorplay-web-application-default-rtdb.firebaseio.com/noorplay-decking.json",
	//"https://d2xowqqrpfxxjf.cloudfront.net/noorplay/web-noorplayv2.json",
	//"https://noorplay-web-application-default-rtdb.firebaseio.com/noorplay-decking.json",
	//noorplay_config: "https://d2xowqqrpfxxjf.cloudfront.net/noorplay/web-noorplayv2.json",
	//"https://noorplay-web-application-default-rtdb.firebaseio.com/noorplay-prodv3.json",
	//"https://d2xowqqrpfxxjf.cloudfront.net/noorplay/web-noorplayv2.json",
	//"https://noorplay-web-application-default-rtdb.firebaseio.com/noorplay-prodv3.json",
	//"https://d2xowqqrpfxxjf.cloudfront.net/noorplay/web-noorplayv2.json",
	//"https://d2xowqqrpfxxjf.cloudfront.net/noorplay/web-noorplayv2.json",
	//"https://d2xowqqrpfxxjf.cloudfront.net/noorplay/web-noorplayv2.json",
	//"https://noorplay-web-application-default-rtdb.firebaseio.com/tpay-staging-sep.json",
	// "https://d2xowqqrpfxxjf.cloudfront.net/noorplay/web-noorplayv2.json",
	//"https://d2xowqqrpfxxjf.cloudfront.net/noorplay/web-noorplayv2.json", // MAIN - v2 - Working - CLOUDFRONT CONFIG URL -- LIVE
	// decking_config:
	// 	"https://noorplay-web-application-default-rtdb.firebaseio.com/decking-t1.json",
	//"https://d27kvsajbyluqu.cloudfront.net/deckingconfig/ultrajhs/trjOLRlT.json",//ultra
	//"https://d27kvsajbyluqu.cloudfront.net/deckingconfig/noorplay/cTsUj6nV.json",//noorplay

	FIRE_FCM_SERVER_KEY:
		// "AAAAAKmmC_0:APA91bEwGOZ8D0IEHXKku0GfVWEqU39D6TZYO91a3VxPI0Lx_DIAQfyuW5XnW-RV1lAZcQhRITVy-lzg4jjODe4dVP39vUNKamH6l4XPKouv7uaKcD5xb3FuA5e0MjnRwObjYB5HT05v", // - Test

		"AAAArYjDz_s:APA91bErpX8I8QtHoYb8zFQjRwASvZsiq4U-wKKjNbpxg1pGWkUggIKemtr9LyP9nXJz_4wXUPyMpbxOYO5pV4MdfwVD4lzKB0xsmHiJiGtXHbNYTkDHgdh74putcvGTfgx3TvkdiaAt", // PROD

	FIRE_FCM_CERTIFICATE_KEY:
		// "BPYcUWM7Ojk0nP-ErERbkoeFo7soLgQBDfXAb6apGURteoXq0sKUofRl0KdTIYw_C36ynJJeRIfalseeheOssiw", // - Test

		"BJobUtAl7lsoUrITGMTOnFbi_ErvI5yZfY7bxmJDLDwmszQ_MM6TfeWIUq1jg_bscA61NShGwEczPXHjRcM1FJ4", // PROD

	//vlive preprod firebase app config
	FIRE_APP_ID: "1:2846231549:web:ba530e27d33ff84064a794",
	FIRE_API_KEY: "AIzaSyAtZ7x59USPZA5FjH-yjgr6hIwHslGiEO0",
	FIRE_AUTH_DOMAIN: "vlive-preprod.firebaseapp.com",
	FIRE_DATABASE_URL: "https://vlive-preprod.firebaseio.com",
	FIRE_PROJECT_ID: "vlive-preprod",
	FIRE_STORAGE_BUCKET: "vlive-preprod.appspot.com",
	FIRE_MESSAGING_SENDER_ID: "2846231549",
	FIRE_MEASUREMENT_ID: "G-E87QVFTB8D",

	//net5 firebase app config
	FIRE_APP_ID_2: "1:860691220599:web:4fb4e4fbe1f8ae946d1e72",
	FIRE_API_KEY_2: "AIzaSyBTTkYv5kp90a2wrsri0BMe4kRGVHo-Z0g",
	FIRE_AUTH_DOMAIN_2: "net5-8cc17.firebaseapp.com",
	FIRE_DATABASE_URL_2: "https://net5-8cc17.firebaseio.com",
	FIRE_PROJECT_ID_2: "net5-8cc17",
	FIRE_STORAGE_BUCKET_2: "net5-8cc17.appspot.com",
	FIRE_MESSAGING_SENDER_ID_2: "860691220599",
	FIRE_MEASUREMENT_ID_2: "G-M1ES0DKRZM",

	//nammaflix firebase app config
	FIRE_APP_ID_3: "1:404583237788:web:823a728ebf8a2f2b26c559",
	FIRE_API_KEY_3: "AIzaSyDm54fqThAKDy-K36kV0oqIFfbOLUMSoXY",
	FIRE_AUTH_DOMAIN_3: "nammaflix-b7b1b.firebaseapp.com",
	FIRE_DATABASE_URL_3: "https://nammaflix-b7b1b.firebaseio.com",
	FIRE_PROJECT_ID_3: "nammaflix-b7b1b",
	FIRE_STORAGE_BUCKET_3: "nammaflix-b7b1b.appspot.com",
	FIRE_MESSAGING_SENDER_ID_3: "404583237788",
	FIRE_MEASUREMENT_ID_3: "G-70B2B4T9DJ",

	//noorplay firebase app config
	FIRE_APP_ID_4: "1:745323876347:web:9ac76ca4767709c3ab055e",
	FIRE_API_KEY_4: "AIzaSyCq1jIjXp8Ehqcgo5Ycvx9GuvxgxNe7ruI",
	FIRE_AUTH_DOMAIN_4: "alnoor-tv-e8e2f.firebaseapp.com",
	FIRE_DATABASE_URL_4: "https://alnoor-tv-e8e2f.firebaseio.com",
	FIRE_PROJECT_ID_4: "alnoor-tv-e8e2f",
	FIRE_STORAGE_BUCKET_4: "alnoor-tv-e8e2f.appspot.com",
	FIRE_MESSAGING_SENDER_ID_4: "745323876347",
	FIRE_MEASUREMENT_ID_4: "G-TJ9SC1E69N",

	//net5 clevertap login user properties.
	net5_login_Name: "Name",
	net5_login_Identity: "Identity",
	net5_login_Email: "Email",
	net5_login_Phone: "Phone",
	net5_login_Gender: "Gender",
	net5_login_DOB: "DOB",
	net5_login_SubscriberId: "subscriber_id",
	net5_login_MSG_EMAIL: "MSG-email",
	net5_login_MSG_PUSH: "MSG-push",
	net5_login_MSG_SMS: "MSG-sms",
};

const getters = {
	//config urls
	vlive_config: (state) => {
		return state.vlive_config;
	},
	net5_config: (state) => {
		return state.net5_config;
	},
	nammaflix_config: (state) => {
		return state.nammaflix_config;
	},
	noorplay_config: (state) => {
		return state.noorplay_config;
	},
	decking_config: (state) => {
		return state.decking_config;
	},
	noorplay_beta: (state) => {
		return state.noorplay_beta;
	},
	noorplay_test: (state) => {
		return state.noorplay_test;
	},

	//vlive firebase config
	FIRE_APP_ID: (state) => {
		return state.FIRE_APP_ID;
	},
	FIRE_API_KEY: (state) => {
		return state.FIRE_API_KEY;
	},
	FIRE_AUTH_DOMAIN: (state) => {
		return state.FIRE_AUTH_DOMAIN;
	},
	FIRE_DATABASE_URL: (state) => {
		return state.FIRE_DATABASE_URL;
	},
	FIRE_PROJECT_ID: (state) => {
		return state.FIRE_PROJECT_ID;
	},
	FIRE_STORAGE_BUCKET: (state) => {
		return state.FIRE_STORAGE_BUCKET;
	},
	FIRE_MESSAGING_SENDER_ID: (state) => {
		return state.FIRE_MESSAGING_SENDER_ID;
	},
	FIRE_MEASUREMENT_ID: (state) => {
		return state.FIRE_MEASUREMENT_ID;
	},

	//net5 firebase config
	FIRE_APP_ID_2: (state) => {
		return state.FIRE_APP_ID_2;
	},
	FIRE_API_KEY_2: (state) => {
		return state.FIRE_API_KEY_2;
	},
	FIRE_AUTH_DOMAIN_2: (state) => {
		return state.FIRE_AUTH_DOMAIN_2;
	},
	FIRE_DATABASE_URL_2: (state) => {
		return state.FIRE_DATABASE_URL_2;
	},
	FIRE_PROJECT_ID_2: (state) => {
		return state.FIRE_PROJECT_ID_2;
	},
	FIRE_STORAGE_BUCKET_2: (state) => {
		return state.FIRE_STORAGE_BUCKET_2;
	},
	FIRE_MESSAGING_SENDER_ID_2: (state) => {
		return state.FIRE_MESSAGING_SENDER_ID_2;
	},
	FIRE_MEASUREMENT_ID_2: (state) => {
		return state.FIRE_MEASUREMENT_ID_2;
	},

	//nammaflix firebase config
	FIRE_APP_ID_3: (state) => {
		return state.FIRE_APP_ID_3;
	},
	FIRE_API_KEY_3: (state) => {
		return state.FIRE_API_KEY_3;
	},
	FIRE_AUTH_DOMAIN_3: (state) => {
		return state.FIRE_AUTH_DOMAIN_3;
	},
	FIRE_DATABASE_URL_3: (state) => {
		return state.FIRE_DATABASE_URL_3;
	},
	FIRE_PROJECT_ID_3: (state) => {
		return state.FIRE_PROJECT_ID_3;
	},
	FIRE_STORAGE_BUCKET_3: (state) => {
		return state.FIRE_STORAGE_BUCKET_3;
	},
	FIRE_MESSAGING_SENDER_ID_3: (state) => {
		return state.FIRE_MESSAGING_SENDER_ID_3;
	},
	FIRE_MEASUREMENT_ID_3: (state) => {
		return state.FIRE_MEASUREMENT_ID_3;
	},

	//noorplay firebase config
	FIRE_APP_ID_4: (state) => {
		return state.FIRE_APP_ID_4;
	},
	FIRE_API_KEY_4: (state) => {
		return state.FIRE_API_KEY_4;
	},
	FIRE_AUTH_DOMAIN_4: (state) => {
		return state.FIRE_AUTH_DOMAIN_4;
	},
	FIRE_DATABASE_URL_4: (state) => {
		return state.FIRE_DATABASE_URL_4;
	},
	FIRE_PROJECT_ID_4: (state) => {
		return state.FIRE_PROJECT_ID_4;
	},
	FIRE_STORAGE_BUCKET_4: (state) => {
		return state.FIRE_STORAGE_BUCKET_4;
	},
	FIRE_MESSAGING_SENDER_ID_4: (state) => {
		return state.FIRE_MESSAGING_SENDER_ID_4;
	},
	FIRE_MEASUREMENT_ID_4: (state) => {
		return state.FIRE_MEASUREMENT_ID_4;
	},

	//clevertap login event consts.
	net5_login_Name: (state) => {
		return state.net5_login_Name;
	},
	net5_login_Identity: (state) => {
		return state.net5_login_Identity;
	},
	net5_login_Email: (state) => {
		return state.net5_login_Email;
	},
	net5_login_Phone: (state) => {
		return state.net5_login_Phone;
	},
	net5_login_Gender: (state) => {
		return state.net5_login_Gender;
	},
	net5_login_DOB: (state) => {
		return state.net5_login_DOB;
	},
	net5_login_SubscriberId: (state) => {
		return state.net5_login_SubscriberId;
	},
	net5_login_MSG_EMAIL: (state) => {
		return state.net5_login_MSG_EMAIL;
	},
	net5_login_MSG_PUSH: (state) => {
		return state.net5_login_MSG_PUSH;
	},
	net5_login_MSG_SMS: (state) => {
		return state.net5_login_MSG_SMS;
	},

	FIRE_FCM_SERVER_KEY: (state) => {
		return state.FIRE_FCM_SERVER_KEY;
	},

	FIRE_FCM_CERTIFICATE_KEY: (state) => {
		return state.FIRE_FCM_CERTIFICATE_KEY;
	},
};

export const consts = { state, getters };